<script setup lang="ts">
</script>

<template>
  <h1>認証</h1>
  <div class="card">
    <button type="button" onclick="location.href='/auth/login'">ログイン</button>
  </div>
</template>

<style scoped>
</style>
